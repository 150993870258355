<template>
  <div class="shelf-list" v-if="shelfInfo?.childList?.length">
    <template v-for="i in +shelfInfo.row" :key="i">
      <div v-if="deffer(i)">
        <div class="shelf-list-title">
          <slot name="title" :row="i"></slot>
        </div>
        <div class="shelf-list-content">
          <template v-for="j in +shelfInfo.column" :key="j">
            <div class="shelf-list-content-item"
                 :class="['shelf-list-content-item-' + i%5 ,shelfInfo.childList[getIndex(i,j)].status?'':'disable' ]">
              <slot :data="shelfInfo.childList[getIndex(i,j)]" :index="getIndex(i,j)" :column="j" :row="i"></slot>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>

</template>

<script setup>
import {computed, toRefs, watchEffect} from "vue";
import useDeffer from "@utils/hooks/useDeffer";

const {frameCount, deffer, updateFrameCount, resetFrameCount} = useDeffer()

const props = defineProps({
  shelfInfo: {
    type: Object,
    default: () => ({})
  }
})
const {shelfInfo} = toRefs(props)
const getIndex = (i, j) => {
  return (i - 1) * shelfInfo.value.column + j - 1
}
watchEffect(() => {
  if (shelfInfo.value.column && shelfInfo.value.row) {
    resetFrameCount()
    updateFrameCount()
  }
})
const width = computed(() => {
  if (!shelfInfo.value.column) return "0%"
  const n = shelfInfo.value.column > 7 ? 7 : shelfInfo.value.column
  return `${(100 / n).toFixed(2)}%`
})
defineExpose({
  frameCount,
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shelfList",
});
</script>

<style lang="scss" scoped>
$mix-color: #fff;
$status-color: (
    1: #57B0FF,
    2: #48CB9F,
    3: #FF910A,
    4:#3859FF,
    0:#FF6669,
);
.shelf-list-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; // 保证在超出一行之后从新一行开始
  &-item {
    flex: 0 0 calc(v-bind(width) - 16px); // 每行显示五个元素，考虑到间隔
    min-width: 216px;
    margin-right: 16px; // 元素之间的间隔
    margin-bottom: 16px; // 换行时的行间距
    height: 120px;
    border-radius: 8px;
    background: #57B0FF;

    &.disable {
      //灰色滤镜
      filter: grayscale(100%);
    }
    @each $key, $value in $status-color {
      &-#{$key} {
        background-color: mix($value, $mix-color, 5%);
      }
    }
  }
}
</style>
