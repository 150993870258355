import {onMounted, ref} from 'vue';

export default function (maxCount = 100) {
    const frameCount = ref(0)
    let rafId;

    function updateFrameCount() {
        rafId = requestAnimationFrame(() => {
            frameCount.value++
            if (frameCount.value >= maxCount) {
                return
            }
            updateFrameCount()
        })
    }

    updateFrameCount()
    onMounted(() => {
        cancelAnimationFrame(rafId)
    })

    function deffer(n) {
        return frameCount.value >= n
    }

    function resetFrameCount() {
        frameCount.value = 0
    }

    return {
        frameCount,
        deffer,
        updateFrameCount,
        resetFrameCount
    }
}